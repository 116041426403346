.iress-theme-dark {
  --iress-badge-background-color--primary: var(--iress-global-primary-color);
  --iress-badge-border-color--primary: var(--iress-global-primary-color);
  --iress-badge-color--primary: var(--iress-global-primary-color--contrast);

  --iress-badge-background-color--success: var(--iress-global-success-color);
  --iress-badge-border-color--success: var(--iress-global-success-color);
  --iress-badge-color--success: var(--iress-color-white);

  --iress-badge-background-color--warning: var(--iress-global-warning-color);
  --iress-badge-border-color--warning: var(--iress-global-warning-color);
  --iress-badge-color--warning: var(--iress-global-warning-color--contrast);

  --iress-badge-background-color--danger: var(--iress-global-danger-color);
  --iress-badge-border-color--danger-border-color: var(--iress-global-danger-color);
  --iress-badge-color--danger: var(--iress-global-danger-color--contrast);

  --iress-badge-background-color--info: var(--iress-global-info-color);
  --iress-badge-border-color--info: var(--iress-global-info-color);
  --iress-badge-color--info: var(--iress-global-info-color--contrast);

  --iress-badge-background-color--transparent: transparent;
  --iress-badge-border-color--transparent: var(--iress-global-text-color);
  --iress-badge-color--transparent: var(--iress-global-text-color);
}
