.iress-theme-dark {
  --iress-checkbox-margin-bottom: var(--iress-spacing-sm);
  --iress-checkbox-margin-bottom-neg: calc(var(--iress-checkbox-margin-bottom) * -1);
  --iress-checkbox-margin-top: var(--iress-spacing-sm);
  --iress-checkbox-padding-left: var(--iress-spacing-lg);
  --iress-checkbox-border-radius: var(--iress-border-radius-sm);
  --iress-checkbox-border-color: var(--iress-border-color--form-control);
  --iress-checkbox-border-color--checked: var(--iress-color-cyan);
  --iress-checkbox-background-color: var(--iress-color-transparent);
  --iress-checkbox-background-color--hover: var(--iress-color-grey-9);
  --iress-checkbox-background-color--checked: var(--iress-color-cyan);
  --iress-checkbox-background-color--checked--hover: var(--iress-color-cyan--ll);
  --iress-checkbox-mark-color: var(--iress-color-grey-11);
}
