.iress-theme-dark {
  --iress-select-border-size: var(--iress-border-sm);
  --iress-select-border-color: var(--iress-border-color--form-control);
  --iress-select-border-color--focus: var(--iress-global-focus-color);
  --iress-select-border-color--invalid: var(--iress-global-danger-color);
  --iress-select-border-color--invalid--focus: var(--iress-global-danger-color);
  --iress-select-box-shadow--focus: var(--iress-global-focus-indicator);
  --iress-select-font-size: var(--iress-font-size-xxs);
  --iress-select-color: var(--iress-global-text-color);
  --iress-select-color--focus: var(--iress-global-text-color);
  --iress-select-placeholder-color: var(--iress-global-text-color--muted);
  --iress-select-padding-x: var(--iress-spacing-xs);
  --iress-select-padding-y: var(--iress-spacing-xs);
  --iress-select-background-color: var(--iress-color-transparent);
  --iress-select-background-color--focus: var(--iress-color-transparent);
  --iress-select-option-color: var(--iress-color-grey-8);
  --iress-select-chevron-color: var(--iress-border-color--form-control);
}
