.iress-theme-dark {
  --iress-radio-border-color: var(--iress-border-color--form-control);
  --iress-radio-border-color--checked: var(--iress-color-cyan);
  --iress-radio-background-color: transparent;
  --iress-radio-background-color--checked: transparent;
  --iress-radio-inner-background-color--hover: var(--iress-color-grey-9);
  --iress-radio-inner-background-color--checked: var(--iress-color-cyan);
  --iress-radio-inner-background-color--checked--hover: var(--iress-color-cyan--ll);
}
.iress-theme-dark.iress-view-mode-touch {
  --iress-radio-margin-bottom: var(--iress-spacing-sm);
  --iress-radio-spacing-right: var(--iress-spacing-sm);
  --iress-radio-toggle-padding: var(--iress-spacing-md);
}
.iress-theme-dark.iress-view-mode-standard {
  --iress-radio-margin-bottom: var(--iress-spacing-xs);
  --iress-radio-spacing-right: var(--iress-spacing-xs);
}
.iress-theme-dark.iress-view-mode-compact {
  --iress-radio-margin-bottom: var(--iress-spacing-xs);
  --iress-radio-spacing-right: var(--iress-spacing-xs);
}
