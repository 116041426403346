.iress-theme-dark {
  --iress-col-1: 8.3333%;
  --iress-col-2: 16.6667%;
  --iress-col-3: 25%;
  --iress-col-4: 33.3333%;
  --iress-col-5: 41.6667%;
  --iress-col-6: 50%;
  --iress-col-7: 58.3333%;
  --iress-col-8: 66.6667%;
  --iress-col-9: 75%;
  --iress-col-10: 83.3333%;
  --iress-col-11: 91.6667%;
  --iress-col-12: 100%;
}
