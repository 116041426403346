.iress-theme-dark {
  /* Font sizes */
  --iress-font-size-xxxs: 0.875rem;
  --iress-font-size-xxs: 1rem;
  --iress-font-size-xs: 1.125rem;
  --iress-font-size-sm: 1.25rem;
  --iress-font-size-md: 1.375rem;
  --iress-font-size-lg: 1.5rem;
  --iress-font-size-xl: 1.75rem;
  --iress-font-size-xxl: 2rem;
  --iress-font-size-xxxl: 2.5rem;
  --iress-font-size-xxxxl: 3.5rem;

  /* Line heights */
  --iress-line-height-sm: 1.2;
  --iress-line-height-md: 1.42857;

  /* Font families */
  /* stylelint-disable value-keyword-case */
  --iress-font-family-base: "Roboto", "Arial", sans-serif;
  --iress-font-weight-base-lighter: 100;
  --iress-font-weight-base-light: 300;
  --iress-font-weight-base-regular: 400;
  --iress-font-weight-base-bold: 500;
  --iress-font-family-code: "Consolas", "Monaco", "Lucida Console", "Courier New", monospace;
  /* stylelint-enable value-keyword-case */
}
