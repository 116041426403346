.iress-theme-dark {
  --iress-alert-heading-font-size: var(--iress-font-size-xs);
  --iress-alert-heading-font-weight: var(--iress-font-weight-base-bold);
  --iress-alert-border-radius: var(--iress-border-radius-md);
  --iress-alert-padding-x: var(--iress-spacing-md);
  --iress-alert-padding-y: var(--iress-spacing-sm);
  --iress-alert-margin-bottom: var(--iress-spacing-lg);
  --iress-alert-border-width: var(--iress-border-sm);

  --iress-alert-background-color--error: var(--iress-global-danger-color--contrast);
  --iress-alert-border-color--error: var(--iress-global-danger-color);
  --iress-alert-color--error: var(--iress-global-danger-color);

  --iress-alert-background-color--warning: var(--iress-global-warning-color--contrast);
  --iress-alert-border-color--warning: var(--iress-global-warning-color);
  --iress-alert-color--warning: var(--iress-global-warning-color);

  --iress-alert-background-color--success: var(--iress-global-success-color--contrast);
  --iress-alert-border-color--success: var(--iress-global-success-color);
  --iress-alert-color--success: var(--iress-global-success-color);

  --iress-alert-background-color--info: var(--iress-global-info-color--contrast);
  --iress-alert-border-color--info: var(--iress-global-info-color);
  --iress-alert-color--info: var(--iress-global-info-color);
}
