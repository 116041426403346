.iress-theme-dark.iress-view-mode-standard {
  --iress-spacing-xxs: 0.25rem; /* 4px */
  --iress-spacing-xs: 0.5rem; /* 8px */
  --iress-spacing-sm: 0.75rem; /* 12px */
  --iress-spacing-md: 1rem; /* 16px */
  --iress-spacing-lg: 1.5rem; /* 24px */
  --iress-spacing-xl: 2rem; /* 32px */
  --iress-spacing-xxl: 3rem; /* 48px */
  --iress-spacing-neg-xxs: -0.25rem; /* -4px */
  --iress-spacing-neg-xs: -0.5rem; /* -8px */
  --iress-spacing-neg-sm: -0.75rem; /* -12px */
  --iress-spacing-neg-md: -1rem; /* -16px */
  --iress-spacing-neg-lg: -1.5rem; /* -24px */
  --iress-spacing-neg-xl: -2rem; /* -32px */
  --iress-spacing-neg-xxl: -3rem; /* -48px */
}
