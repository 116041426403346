.iress-theme-dark {
  --iress-tab-button-border-bottom-width: 2px;
  --iress-tab-button-border-color: transparent;
  --iress-tab-button-color: var(--iress-global-text-color);
  --iress-tab-button-padding-x: var(--iress-spacing-xs);

  --iress-tab-button-background-color--hover: var(--iress-color-grey-10);
  --iress-tab-button-border-color--hover: var(--iress-color-grey-10);
  --iress-tab-button-color--hover: var(--iress-global-text-color);

  --iress-tab-button-background-color--active: transparent;
  --iress-tab-button-border-color--active: transparent;
  --iress-tab-button-border-bottom-color--active: var(--iress-global-link-color);
  --iress-tab-button-color--active: var(--iress-global-link-color);

  --iress-tab-button-background-color--active--hover: var(--iress-color-grey-10);
  --iress-tab-button-color--active--hover: var(--iress-tab-button-color--active);
}
