@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap");

html {
  box-sizing: border-box;
  font-size: var(--iress-global-font-size, 0.875rem);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: var(--iress-global-font-family, sans-serif);
  color: var(--iress-global-text-color, #333);
  font-weight: var(--iress-global-font-weight, normal);
  background-color: var(--iress-global-background-color, #fff);
  line-height: var(--iress-global-line-height, 1.5);
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
dl,
blockquote {
  margin-top: 0;
}

a {
  color: var(--iress-global-link-color);
  text-decoration: var(--iress-global-link-text-decoration);
  font-weight: var(--iress-global-link-font-weight);
}

a:hover,
a:focus {
  color: var(--iress-global-link-color--hover);
  text-decoration: var(--iress-global-link-text-decoration--hover);
}
a:focus {
  outline: none;
  box-shadow: var(--iress-global-focus-indicator);
}
a:active,
.link:active {
  color: var(--iress-global-link-color--active);
  text-decoration: var(--iress-global-link-text-decoration--active);
}

pre > code {
  position: relative;
  display: block;
  overflow: auto;
  padding: var(--iress-spacing-md) var(--iress-spacing-md) var(--iress-spacing-md) var(--iress-spacing-xl);
  border: var(--iress-global-code-border);
  border-color: transparent;
}

hr {
  border: 1px solid var(--iress-border-color--standard);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
