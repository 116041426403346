.iress-theme-dark {
  --iress-color-purple: hsl(283, 43%, 19%);
  --iress-color-purple--l: hsl(283, 43%, 24%); /* Headings */
  --iress-color-purple--ll: hsl(283, 43%, 29%);
  --iress-color-purple--lll: hsl(283, 43%, 34%);
  --iress-color-purple--llll: hsl(283, 43%, 39%);
  --iress-color-pink: hsl(351, 100%, 63%);
  --iress-color-green: hsl(158, 91%, 55%);
  --iress-color-green--l: hsl(158, 91%, 70%); /* ?? */
  --iress-color-green--ll: hsl(158, 91%, 80%); /* ?? */
  --iress-color-cyan: hsl(188, 100%, 50%);
  --iress-color-cyan--contrast: hsl(188, 100%, 10%);
  --iress-color-cyan--d: hsl(188, 100%, 40%);
  --iress-color-cyan--dd: hsl(188, 100%, 30%);
  --iress-color-cyan--l: hsl(188, 100%, 60%);
  --iress-color-cyan--ll: hsl(188, 100%, 70%);
  --iress-color-yellow: hsl(55, 98%, 53%);
  --iress-color-grey: hsl(300, 9%, 93%);
  --iress-color-blackberry: hsl(316, 100%, 13%);
  --iress-color-jade: hsl(155, 100%, 29%);
  --iress-color-jade--l: hsl(155, 100%, 34%);
  --iress-color-jade--l-contrast: hsl(155, 100%, 9%);
  --iress-color-jade--d: hsl(155, 100%, 25%);
  --iress-color-jade--d-contrast: hsl(155, 100%, 90%);
  --iress-color-maroon: hsl(347, 68%, 45%);
  --iress-color-maroon--contrast: hsl(347, 68%, 93%);
  --iress-color-s-yellow: hsl(43, 100%, 45%); /* 6.7:1 dark */
  --iress-color-s-yellow--contrast: hsl(43, 100%, 10%);
  --iress-color-s-blue: hsl(206, 100%, 40%);
  --iress-color-s-blue--l: hsl(206, 100%, 50%); /* Dark focus, link & info */
  --iress-color-s-blue--d: hsl(206, 100%, 35%); /* ?? */
  --iress-color-s-blue--dd: hsl(206, 100%, 20%); /* ?? */
  --iress-color-s-blue--ddd: hsl(206, 100%, 10%); /* ?? */
  --iress-color-s-blue--d-contrast: hsl(206, 100%, 95%);
  --iress-color-chatelle: hsl(280, 10%, 77%);
  --iress-color-white: hsl(0, 0%, 100%);
  --iress-color-black: hsl(0, 0%, 0%);
  --iress-color-grey-12: hsl(0, 0%, 7%); /* light text, dark page bg */
  --iress-color-grey-11: hsl(0, 0%, 15%); /* dark theme alt panel */
  --iress-color-grey-10: hsl(0, 0%, 23%);
  --iress-color-grey-9: hsl(0, 0%, 31%);
  --iress-color-grey-8: hsl(0, 0%, 38%); /* light muted, dark placeholder */
  --iress-color-grey-7: hsl(0, 0%, 46%); /* dark theme border (3:1) */
  --iress-color-grey-6: hsl(0, 0%, 54%); /* light theme border (3:1) */
  --iress-color-grey-5: hsl(0, 0%, 62%); /* Dark muted, light placeholder */
  --iress-color-grey-4: hsl(0, 0%, 69%);
  --iress-color-grey-3: hsl(0, 0%, 77%);
  --iress-color-grey-2: hsl(0, 0%, 85%); /* dark theme heading & lead text */
  --iress-color-grey-1: hsl(0, 0%, 92%); /* dark theme text */
  --iress-color-grey-0: hsl(0, 0%, 97%); /* light theme alt panel */
  --iress-color-grey-trans-l: rgba(204, 204, 204, 0.08); /* transparent grey light for documentation purposes */
  --iress-color-grey-trans-d: rgba(204, 204, 204, 0.3); /* transparent grey dark for documentation purposes */
  --iress-color-orange--light-theme: hsl(11, 91%, 44%); /* 3:1 dark */
  --iress-color-orange--dark-theme: hsl(11, 91%, 59%); /* 4.5:1 dark */
  --iress-color-orange--light-contrast: hsl(11, 91%, 97%);
  --iress-color-orange--dark-contrast: hsl(11, 91%, 10%);
  --iress-color-transparent: transparent;
  --iress-color-buy-6: hsl(86, 53%, 31%);
  --iress-color-buy-5: hsl(86, 53%, 36%);
  --iress-color-buy-4: hsl(86, 53%, 41%);
  --iress-color-buy-3: hsl(86, 53%, 51%);
  --iress-color-buy-2: hsl(86, 53%, 61%);
  --iress-color-buy-1: hsl(86, 53%, 71%);
  --iress-color-sell-5: hsl(2, 84%, 45%);
  --iress-color-sell-4: hsl(2, 84%, 55%);
  --iress-color-sell-3: hsl(2, 84%, 65%);
  --iress-color-sell-2: hsl(2, 84%, 75%);
  --iress-color-sell-1: hsl(2, 84%, 85%);
}
