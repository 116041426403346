.iress-theme-dark {
  /* Border-width */
  --iress-border-none: 0;
  --iress-border-sm: 1px;
  --iress-border-lg: 2px;

  /* Border radius */
  --iress-border-radius-sm: 0.125rem;
  --iress-border-radius-md: 0.25rem;
  --iress-border-radius-lg: 0.75rem;

  /* Border colors */
  --iress-border-color--standard: var(--iress-color-grey-3);
  --iress-border-color--form-control: var(--iress-color-grey-7);
}
