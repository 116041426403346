.iress-theme-dark {
  /* base */
  --iress-global-background-color: var(--iress-color-grey-12);
  --iress-global-background-color--alt: var(--iress-color-grey-11);
  --iress-global-focus-color: var(--iress-color-s-blue--l);
  --iress-global-focus-width: 2px;
  --iress-global-focus-indicator: 0 0 0 var(--iress-global-focus-width, 2px) var(--iress-global-focus-color, blue);

  /* Buy / sell colours */
  --iress-global-sell-color: var(--iress-color-sell-3);
  --iress-global-sell-color--hover: var(--iress-color-sell-2);
  --iress-global-sell-color--active: var(--iress-color-sell-1);
  --iress-global-buy-color: var(--iress-color-buy-3);
  --iress-global-buy-color--hover: var(--iress-color-buy-2);
  --iress-global-buy-color--active: var(--iress-color-buy-1);

  /* Core theme colours */
  --iress-global-primary-color: var(--iress-color-cyan);
  --iress-global-primary-color--contrast: var(--iress-color-cyan--contrast);
  --iress-global-danger-color: var(--iress-color-orange--dark-theme);
  --iress-global-danger-color--contrast: var(--iress-color-orange--dark-contrast);
  --iress-global-warning-color: var(--iress-color-s-yellow);
  --iress-global-warning-color--contrast: var(--iress-color-s-yellow--contrast);
  --iress-global-success-color: var(--iress-color-jade--l);
  --iress-global-success-color--contrast: var(--iress-color-jade--l-contrast);
  --iress-global-info-color: var(--iress-color-s-blue--l);
  --iress-global-info-color--contrast: var(--iress-color-s-blue--ddd);
}
