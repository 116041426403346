.iress-theme-dark {
  /* base */
  --iress-global-text-color: var(--iress-color-grey-3);
  --iress-global-text-color--contrast: var(--iress-color-grey-11);
  --iress-global-text-color--muted: var(--iress-color-grey-5);
  --iress-global-font-family: var(--iress-font-family-base);
  --iress-global-line-height: var(--iress-line-height-md);

  /* links */
  --iress-global-link-color: var(--iress-color-cyan);
  --iress-global-link-text-decoration: underline;
  --iress-global-link-color--hover: var(--iress-color-cyan--d);
  --iress-global-link-text-decoration--hover: none;
  --iress-global-link-color--active: var(--iress-color-cyan--dd);
  --iress-global-link-text-decoration--active: none;

  /* headings */
  --iress-global-heading-font-family: var(--iress-font-family-base);
  --iress-global-h1-font-weight: var(--iress-font-weight-base-lighter);
  --iress-global-heading-font-weight: var(--iress-font-weight-base-light);
  --iress-global-heading-line-height: var(--iress-line-height-md);
  --iress-global-heading-margin-top: var(--iress-spacing-xl);
  --iress-global-heading-margin-bottom: var(--iress-spacing-xs);
  --iress-global-heading-color: var(--iress-color-grey-1);
  --iress-global-h1-font-size: var(--iress-font-size-xxl);
  --iress-global-h2-font-size: var(--iress-font-size-lg);
  --iress-global-h3-font-size: var(--iress-font-size-md);
  --iress-global-h4-font-size: var(--iress-font-size-sm);
  --iress-global-h5-font-size: var(--iress-font-size-xs);
  --iress-global-h6-font-size: var(--iress-font-size-xxs);

  /* display */
  --iress-global-display-font-family: var(--iress-font-family-base);
  --iress-global-display-font-weight: var(--iress-font-weight-base-lighter);
  --iress-global-display-line-height: var(--iress-line-height-sm);
  --iress-global-display-margin-top: 0;
  --iress-global-display-margin-bottom: 0;
  --iress-global-display-color: var(--iress-color-grey-3);
  --iress-global-display1-font-size: var(--iress-font-size-xxxxl);
  --iress-global-display2-font-size: var(--iress-font-size-xxxl);
  --iress-global-display3-font-size: var(--iress-font-size-xl);

  /* paragraph */
  --iress-global-paragraph-margin-bottom: var(--iress-spacing-md);

  /* lists */
  --iress-global-list-margin-bottom: var(--iress-spacing-md);
  --iress-global-list-padding-x: var(--iress-spacing-xl);

  /* lead */
  --iress-global-lead-color: var(--iress-color-grey-2);
  --iress-global-lead-font-size: var(--iress-font-size-sm);
  --iress-global-lead-font-weight: var(--iress-font-weight-base-light);

  /* Placeholder */
  --iress-global-placeholder-color: var(--iress-color-grey-8);

  /* caption */
  --iress-global-caption-color: var(--iress-global-text-color--muted);
  --iress-global-caption-font-size: inherit;

  /* code */
  --iress-global-code-color: var(--iress-color-s-yellow);
  --iress-global-code-background-color: var(--iress-global-background-color--alt);
  --iress-global-code-border: transparent;
  --iress-global-code-border-color: var(--iress-color-grey-2);
  --iress-global-code-font-family: var(--iress-font-family-code);
  --iress-global-code-font-size: inherit;
  --iress-global-code-padding: 2px 4px;
  --iress-global-code-line-height: var(--iress-line-height-md);
}

/* View modes - need to be moved to component vars (with defaults) when text component is created
 as these aren't actually related to the theme */
.iress-theme-dark.iress-view-mode-touch {
  --iress-global-caption-font-weight: var(--iress-font-weight-base-light);
  --iress-global-font-size: 16px;
  --iress-global-font-weight: var(--iress-font-weight-base-regular);
  --iress-global-link-font-weight: var(--iress-font-weight-base-regular);
}
.iress-theme-dark.iress-view-mode-standard {
  --iress-global-caption-font-weight: var(--iress-font-weight-base-light);
  --iress-global-font-size: 14px;
  --iress-global-font-weight: var(--iress-font-weight-base-regular);
  --iress-global-link-font-weight: var(--iress-font-weight-base-regular);
}
.iress-theme-dark.iress-view-mode-compact {
  --iress-global-caption-font-weight: var(--iress-font-weight-base-regular);
  --iress-global-font-size: 12px;
  --iress-global-font-weight: var(--iress-font-weight-base-bold);
  --iress-global-link-font-weight: var(--iress-font-weight-base-bold);
}
