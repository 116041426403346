.iress-theme-dark {
  --iress-panel-border-width: var(--iress-border-none);
  --iress-panel-border-style: solid;
  --iress-panel-padding-sm: var(--iress-spacing-md);
  --iress-panel-padding-lg: var(--iress-spacing-xl);
  --iress-panel-background-color: var(--iress-global-background-color--alt);
  --iress-panel-color: var(--iress-global-text-color);
  --iress-panel-border-color: transparent;
  --iress-panel-background-color--inversed: var(--iress-global-background-color);
  --iress-panel-color--inversed: var(--iress-global-text-color);
  --iress-panel-border-color--inversed: transparent;
}
