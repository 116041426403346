.iress-theme-dark {
  --iress-input-border-size: var(--iress-border-sm);
  --iress-input-border-color: var(--iress-border-color--form-control);
  --iress-input-border-color--focus: var(--iress-global-focus-color);
  --iress-input-border-color--invalid: var(--iress-global-danger-color);
  --iress-input-border-color--invalid--focus: var(--iress-global-danger-color);
  --iress-input-box-shadow--focus: var(--iress-global-focus-indicator);
  --iress-input-border-radius: var(--iress-border-radius-sm);
  --iress-input-font-size: var(--iress-global-font-size);
  --iress-input-color: var(--iress-global-text-color);
  --iress-input-color--focus: var(--iress-global-text-color);
  --iress-input-watermark-color: var(--iress-global-text-color--muted);
  --iress-input-placeholder-color: var(--iress-global-placeholder-color);
  --iress-input-padding-x: var(--iress-spacing-xs);
  --iress-input-padding-y: var(--iress-spacing-xs);
  --iress-input-background-color: var(--iress-color-transparent);
  --iress-input-background-color--focus: var(--iress-color-transparent);
  /* stylelint-disable */
  --iress-input-transition: background-color, color, border-color var(--iress-hover-transition-timing-function) var(--iress-hover-transition-duration);
  /* stylelint-enable */
  --iress-input-label-padding-bottom: var(--iress-spacing-xxs);
}
.iress-theme-dark.iress-view-mode-touch {
  --iress-input-min-height: 2.5rem;
}
.iress-theme-dark.iress-view-mode-standard {
  --iress-input-min-height: 2.5rem;
}
.iress-theme-dark.iress-view-mode-compact {
  --iress-input-min-height: 2rem;
}
